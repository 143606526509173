/* .jumbotron {
  background-image: url("./img/herobg3.jpeg");
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
} */
.heroImgContainer {
  text-align: center;
}

.heroImg {
  width: auto;
  max-height: 300px;
  margin-left: auto;
  margin-right: auto;
}
